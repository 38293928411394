@use '@angular/material' as mat;
@use '../variables';

// Material Tooltip

@mixin mm-component-material-tooltip($theme: variables.$theme-selected) {
  $color: mat.m2-get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mm-tooltip-info,
  .mat-tooltip.mm-tooltip-info {
    position: relative;
    top: 50%;
    margin: 0 14px;
    background-color: mat.m2-get-color-from-palette($primary-color, A100);
    padding: 20px;
    box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.05);
    color: mat.m2-get-color-from-palette(variables.$mm-blue, 800);
  }
}

@mixin mm-component-material-tooltip-color($theme: variables.$theme-selected) {
  $color: mat.m2-get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mm-tooltip-info,
  .mat-tooltip.mm-tooltip-info {
    background-color: mat.m2-get-color-from-palette($primary-color, A100);
    color: mat.m2-get-color-from-palette($accent-color, 800);
  }
}
