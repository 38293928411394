@use '@angular/material' as mat;
@use 'styles/variables';
@use './styles/components';

html,
body {
  height: 100%;
}

body {
  @include mat.m2-typography-level(variables.$mm-typography, body-1);
  margin: 0;
  color: mat.m2-get-color-from-palette(variables.$mm-blue, 800);
}

// Init Material theme and Movinmotion components
@include mat.all-component-typographies(variables.$mm-typography);
@include mat.elevation-classes();
@include mat.app-background();
@include mat.all-component-themes(variables.$theme-selected);
@include components.mm-components(variables.$theme-selected);

.accounting {
  @include mat.all-component-colors(variables.$mm-accounting-theme);
  @include components.mm-components-color(variables.$mm-accounting-theme);
}

.social {
  @include mat.all-component-colors(variables.$mm-social-theme);
  @include components.mm-components-color(variables.$mm-social-theme);
}

.talents {
  @include mat.all-component-colors(variables.$mm-talents-theme);
  @include components.mm-components-color(variables.$mm-talents-theme);
}

.worker {
  @include mat.all-component-colors(variables.$mm-worker-theme);
  @include components.mm-components-color(variables.$mm-worker-theme);
}

.fft {
  @include mat.all-component-colors(variables.$mm-film-france-theme);
  @include components.mm-components-color(variables.$mm-film-france-theme);
}

.odalie {
  @include mat.all-component-colors(variables.$mm-odalie-theme);
  @include components.mm-components-color(variables.$mm-odalie-theme);
}
