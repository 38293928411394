@use '@angular/material' as mat;
@use '../variables';

// Loading

@mixin mm-component-loading($theme: variables.$theme-selected) {
  $color: mat.m2-get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
    }
    100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
    }
  }

  .mm-loading {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    & .logo {
      width: 70px;
      height: 70px;
      background: variables.$logo center center no-repeat;
      background-size: contain;
    }

    & .spinner {
      height: 150px;
      width: 150px;
      animation: rotate 2s linear infinite;
      transform-origin: center center;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;

      & .path {
        stroke-dasharray: 1, 100;
        stroke-dashoffset: 0;
        animation: dash 1.5s ease-in-out infinite;
        stroke-linecap: round;
        stroke: dimgrey;
      }
    }
  }
}

@mixin mm-component-loading-color($theme: variables.$theme-selected) {
  $color: mat.m2-get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);
}
