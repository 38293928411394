@use '@angular/material' as mat;
@use '../variables';

// Sidenav

@mixin mm-component-sidenav($theme: variables.$theme-selected) {
  $color: mat.m2-get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mm-sidenav {
    padding: 0 0 20px 0;
    min-height: 100%;

    & .header {
      padding: 24px;
      box-shadow: -4px 4px 20px rgba(0, 0, 0, 0.05);
      margin-bottom: 30px;
    }

    & .content,
    & .action-container {
      padding: 0 20px;
    }
  }
}

@mixin mm-component-sidenav-color($theme: variables.$theme-selected) {
  $color: mat.m2-get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);
}
