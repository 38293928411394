@use '@angular/material' as mat;
@use '../variables';

// Typography

@mixin mm-component-typography($theme: variables.$theme-selected) {
  $color: mat.m2-get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mat-h4,
  .mat-3 {
    text-transform: uppercase;
  }
  .mat-headline-1 {
    @include mat.m2-typography-level(variables.$mm-typography, headline-1);
  }
  .mat-headline-2 {
    @include mat.m2-typography-level(variables.$mm-typography, headline-2);
  }
  .mat-body-large,
  .mat-body-3 {
    @include mat.m2-typography-level(variables.$mm-typography, body-3);
  }
  .mat-body-large-strong,
  .mat-body-4 {
    @include mat.m2-typography-level(variables.$mm-typography, body-4);
  }
  .mat-small-4 {
    @include mat.m2-typography-level(variables.$mm-typography, small-4);
  }
  .mat-small-3 {
    @include mat.m2-typography-level(variables.$mm-typography, small-3);
  }
  .mat-small-2 {
    @include mat.m2-typography-level(variables.$mm-typography, small-2);
  }
  .mat-small-1 {
    @include mat.m2-typography-level(variables.$mm-typography, small-1);
  }
  .mat-medium-1 {
    @include mat.m2-typography-level(variables.$mm-typography, medium-1);
  }
  .mat-medium-2 {
    @include mat.m2-typography-level(variables.$mm-typography, medium-2);
  }
  .mm-text-uppercase {
    text-transform: uppercase;
  }
  .mm-text-center {
    text-align: center;
  }
  .mm-text-left {
    text-align: left;
  }
  .mm-text-right {
    text-align: right;
  }
  .mm-text-spacing {
    letter-spacing: 0.2em;
  }
}

@mixin mm-component-typography-color($theme: variables.$theme-selected) {
  $color: mat.m2-get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);
}
