@use '@angular/material' as mat;
@use '../variables';

// Material Card

@mixin mm-component-material-card($theme: variables.$theme-selected) {
  $color: mat.m2-get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mat-card {
    &.mat-focus-indicator {
      width: 100%;
      padding: 30px;
      border-radius: 5px;
      border: 1px solid mat.m2-get-color-from-palette($accent-color, 100);
    }

    & .mat-card-content,
    & .mat-card-header-text,
    & .mat-card-footer {
      margin-left: 0;
      margin-right: 0;
    }

    & .mat-card-header .mat-card-title {
      @include mat.m2-typography-level(variables.$mm-typography, button);
      text-transform: uppercase;
    }

    &:not([class*='mat-elevation-z']) {
      box-shadow: none;
    }
  }
}

@mixin mm-component-material-card-color($theme: variables.$theme-selected) {
  $color: mat.m2-get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mat-card {
    &.mat-focus-indicator {
      border-color: mat.m2-get-color-from-palette($accent-color, 100);
    }
  }
}
