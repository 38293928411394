@use '@angular/material' as mat;
@use '../variables';

// Material Menu

@mixin mm-component-material-menu($theme: variables.$theme-selected) {
  $color: mat.m2-get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mm-menu,
  .mat-menu-panel.mm-menu {
    min-width: 220px;
    max-width: 300px;
    margin: 7px 0 0 0;
    box-shadow: 0 4px 20px mat.m2-get-color-from-palette($warn-color, 160);
    border: 1px solid mat.m2-get-color-from-palette($warn-color, 10);
    border-radius: 2px;

    & a {
      text-decoration: none;
    }

    & .mat-menu-content {
      padding: 0;
    }

    & .mat-menu-item {
      height: auto;
      padding: 7.5px 20px;
      line-height: normal;

      &:hover:not([disabled]) {
        background: mat.m2-get-color-from-palette($warn-color, 10);
      }

      &:hover .mat-button {
        background: mat.m2-get-color-from-palette($warn-color, 10);
      }

      &:first-child {
        margin-top: 10px;
      }

      &:last-child {
        margin-bottom: 10px;
      }
    }

    & .item-service {
      padding-top: 10px;
      padding-bottom: 10px;

      &.inactive {
        background: mat.m2-get-color-from-palette($warn-color, 10);
      }

      &:first-child.inactive {
        margin-top: 0;
        padding-top: 20px;
      }

      &:last-child.inactive {
        margin-bottom: 0;
        padding-bottom: 20px;
      }

      & .title {
        @include mat.m2-typography-level(variables.$mm-typography, button);
        line-height: normal;
        display: flex;
        justify-content: space-between;
        align-items: center;

        & .name {
          text-transform: uppercase;
        }

        & .button {
          line-height: 14px;

          & span {
            font-weight: normal;
            font-size: 9px;
          }
        }
      }

      & .description {
        margin-top: 5px;
        @include mat.m2-typography-level(variables.$mm-typography, caption);
        color: mat.m2-get-color-from-palette($accent-color, 150);
        white-space: normal;
        line-height: normal;
      }
    }

    & .overlay {
      width: 100%;

      &.align-item {
        padding-left: 10px;
        padding-right: 10px;
      }

      &:hover:not([disabled]) {
        background: transparent;
      }

      & > a,
      & > button {
        width: 100%;
        background: mat.m2-get-color-from-palette($warn-color, 10);
        padding: 10px;
        @include mat.m2-typography-level(variables.$mm-typography, medium-2);
        line-height: 17px;

        & .mat-button-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;

          & mat-icon {
            width: 13px;
            height: 14px;
            margin-left: 10px;
            margin-right: 0;
          }
        }
      }
    }
  }
}

@mixin mm-component-material-menu-color($theme: variables.$theme-selected) {
  $color: mat.m2-get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mm-menu,
  .mat-menu-panel.mm-menu {
    box-shadow: 0 4px 20px mat.m2-get-color-from-palette($warn-color, 160);
    border-color: mat.m2-get-color-from-palette($warn-color, 10);

    & .mat-menu-item {
      &:hover:not([disabled]) {
        background: mat.m2-get-color-from-palette($warn-color, 10);
      }

      &:hover .mat-button {
        background: mat.m2-get-color-from-palette($warn-color, 10);
      }
    }

    & .overlay {
      & > a,
      & > button {
        background: mat.m2-get-color-from-palette($warn-color, 10);
      }
    }
  }
}
