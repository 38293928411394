@use '@angular/material' as mat;
@use '../variables';

// Material Toolbar

@mixin mm-component-material-toolbar($theme: variables.$theme-selected) {
  .mat-toolbar {
    &.mat-toolbar-single-row,
    &.mat-toolbar-multiple-rows,
    & .mat-toolbar-row {
      background: transparent;
      height: auto;
      padding: 0;
    }
  }
}

@mixin mm-component-material-toolbar-color($theme: variables.$theme-selected) {
  $color: mat.m2-get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);
}
