@use '@angular/material' as mat;
@use '../variables';

// Material Chip (tag)

@mixin mm-component-material-chip($theme: variables.$theme-selected) {
  $color: mat.m2-get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);

  .mat-chip.mat-standard-chip {
    border-radius: 5px;
    padding: 5px 10px;
    margin: 0 5px 0 0;
    min-height: 26px;
    &.mat-primary {
      background-color: mat.m2-get-color-from-palette($primary-color, 10);
      color: mat.m2-get-color-from-palette($primary-color, 800);
      &.light {
        color: mat.m2-get-color-from-palette($primary-color, 500);
      }
    }
    &.mat-accent {
      background-color: mat.m2-get-color-from-palette($accent-color, 100);
      color: mat.m2-get-color-from-palette($accent-color, 800);
      &.light {
        color: mat.m2-get-color-from-palette($accent-color, 500);
      }
    }
  }
  .mat-chip-list.no-effect .mat-chip.mat-standard-chip,
  .mat-chip.mat-standard-chip.no-effect {
    & .mat-chip-ripple {
      display: none;
    }
    &:active {
      box-shadow: none;
    }
    &::after {
      background: none;
    }
  }
}

@mixin mm-component-material-chip-color($theme: variables.$theme-selected) {
  $color: mat.m2-get-color-config($theme);
  $primary-color: map-get($color, primary);
  $accent-color: map-get($color, accent);
  $warn-color: map-get($color, warn);
}
